<template>
    <client-page>
        <template #subHead>
            <sub-visual-quaternary tit="베스트상품" bg="/images/shop/best/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
                <template #tab>
                    <best-list-tabs />
                </template>
            </sub-visual-quaternary>
        </template>

        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <!-- <v-row justify="end">
                <v-col cols="auto">
                    <v-tabs hide-slider>
                        <best-list-sort-tabs />
                    </v-tabs>
                </v-col>
            </v-row> -->

            <!-- Best 3 -->
            <shop-product-items>
                <shop-product-item v-for="(product, index) in bestProducts" :key="index" :value="product" cols="12" :rank="index + 1" crown />
            </shop-product-items>
            <!-- // Best 3 -->

            <v-divider dark class="my-40px my-lg-60px" />

            <div class="product-lists">
                <div class="product-lists-body">
                    <shop-product-items>
                        <shop-product-item v-for="(item, index) in items" :key="index" :value="item" sm="6" md="3" lg="3" :rank="item.rank" />
                    </shop-product-items>

                    <div class="product-lists__pagination">
                        <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                    </div>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import SubVisualQuaternary from "@/components/client/sub/sub-visual-quaternary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';

import BestListTabs from "@/components/client/shop/best/best-list-tabs.vue";
import BestListSortTabs from "@/components/client/shop/best/best-list-sort-tabs.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,

        SubVisualQuaternary,
        VisualBreadcrumbs,

        BestListTabs,
        BestListSortTabs,

        ShopProductItems,
        ShopProductItem,
        PaginationComponent,
    },
    mounted() {
        this.init();
    },
    data: () => ({
        bestProducts: [],
        products: [],
        summary: {
            totalCount: 0,
        },
        limit: 8,
    }),
    computed: {
        items() {
            return (this.products || []).map((item, index) => ({ ...item, rank: (this.page - 1) * this.limit + index + 4 }));
        },
        // ...mapState(["categories", "areCategoriesLoaded"]),
        // categories_children() {
        //     return this.categories.flatMap(({ children }) => children);
        // },
        // categories_grandChildren() {
        //     return this.categories_children.flatMap(({ children }) => children);
        // },
        // categories_granderChildren() {
        //     return this.categories_grandChildren.flatMap(({ children }) => children);
        // },
        // category() {
        //     const categoryCode = this.$route.query.category;
        //     if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode);
        // },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil((this.summary.totalCount - 3) / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
        sort() {
            return JSON.stringify({
                'meta.sellCount': -1,
                index: -1,
                sequence: -1
            });
        }
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            try {
                const { products: bestProducts } = await api.v1.shop.bests.gets({
                    headers: { skip: 0, limit: 3, sort: this.sort },
                    params: this.params,
                });

                const { summary, products } = await api.v1.shop.bests.gets({
                    headers: { skip: this.skip + 3, limit: this.limit, sort: this.sort },
                    params: this.params,
                });

                this.bestProducts = bestProducts;
                this.products = products;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
    watch: {
        "$route.query"() {
            this.search();
        },
    },
};
</script>