<template>
    <v-sheet v-if="product?.flags?.length">
        {{ product.flags.map((text) => `[${text}]`).join(" ") }}
    </v-sheet>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>