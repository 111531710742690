<template>
    <v-chip small outlined color="red" v-if="text != undefined">{{ text }}</v-chip>
</template>

<script>
import { initUserCoupon } from "@/assets/variables";
export default {
    props: {
        coupon: { type: Object, default: initUserCoupon },
    },
    computed: {
        text() {
            const { endsAt } = this.coupon.usage || {};
            if (endsAt) {
                const diff = this.$dayjs(endsAt).startOf("day").diff(this.$dayjs().startOf("day"), "day");
                if (diff < 0) return;

                switch (diff) {
                    case 0: {
                        return "D-0";
                    }
                    default: {
                        return `D-${diff}`;
                    }
                }
            }
        },
    },
};
</script>

<style>
</style>