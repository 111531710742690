var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.text != undefined ? _c('v-chip', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "red"
    }
  }, [_vm._v(_vm._s(_vm.text))]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }