<template>
    <v-row class="row--sm">
        <v-col cols="12" v-for="i in length" :key="i">
            <v-file-input v-model="images[i - 1]" dense outlined hide-details placeholder="파일을 업로드해 주세요." accept="image/*" @change="emit" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] },
        max: { type: Number, default: 10 },
    },
    data: () => ({
        images: [],
    }),
    computed: {
        length() {
            return this.images.length + 1 < this.max ? this.images.length + 1 : this.max;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.images = [...(this.value || [])];
        },
        emit() {
            this.images = this.images.filter((item) => !!item);
            this.$emit("input", this.images);
        },
    },
};
</script>

<style></style>
