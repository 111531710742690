<template>
    <like-btn :params="{ _product: product?._id }">
        <template #activator="{ active, toggle }">
            <v-btn icon rounded outlined color="transparent" class="mobonWish" @click="toggle">
                <span v-if="active" class="material-icons-outlined primary--text">favorite</span>
                <span v-else class="material-icons-outlined grey--text text--darken-4">favorite_border</span>
            </v-btn>
        </template>
    </like-btn>
</template>

<script>
import { initProduct } from "@/assets/variables";

import LikeBtn from "@/components/client/like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style></style>
