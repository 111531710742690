var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('product-list-sort', {
    attrs: {
      "color": "transparent"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var items = _ref.items,
          active = _ref.active,
          setSort = _ref.setSort;
        return [_c('v-select', _vm._b({
          staticClass: "mw-md-160px ml-auto",
          attrs: {
            "value": active,
            "item-text": "text",
            "return-object": "",
            "placeholder": "정렬"
          },
          on: {
            "change": setSort
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input, {
          items
        }), false))];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }