<template>
    <v-sheet>
        <div class="txt txt--sm txt--dark font-weight-medium mb-4px">
            {{ product.name }}
        </div>
        <div class="d-flex align-center font-size-20 font-size-lg-16">
            <template v-if="product.discountPrice">
                <strong class="secondary--text mr-8px">{{ product.discountRate }}%</strong>
            </template>
            <template v-if="product.salePrice">
                <strong class="d-inline-flex align-center">
                    {{ product.salePrice.format() }}
                    <small class="font-size-14 font-weight-regular pl-2px">원</small>
                </strong>
            </template>
            <template v-if="product.discountPrice">
                <span class="font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px">
                    {{ `${product.price.format()}원` }}
                </span>
            </template>
        </div>
    </v-sheet>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>