<template>
    <div class="mw-sm-300px mx-auto">
        <!-- 기본 이미지 -->
        <v-card rounded outlined height="300" class="overflow-hidden" v-bind="{ loading }">
            <v-carousel v-model="index" hide-delimiters :show-arrows="false">
                <v-carousel-item v-for="image in images" :key="image._id">
                    <v-img contain :aspect-ratio="1 / 1" v-bind="image" />
                </v-carousel-item>
            </v-carousel>
        </v-card>
        <!-- 추가 이미지 -->
        <div class="pt-4px pt-md-8px">
            <v-slide-group v-model="index" show-arrows center-active>
                <v-slide-item v-for="image in images" :key="image._id" v-slot="{ active, toggle }">
                    <v-card outlined :color="active ? 'primary' : 'transparent'" class="product-intro__thumb-button overflow-hidden ma-1" @click="toggle">
                        <v-img v-bind="image" width="60" height="60" />
                    </v-card>
                </v-slide-item>
            </v-slide-group>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        _image: { type: String },
        _review: { type: String },
    },
    data: () => ({
        images: [],

        index: 0,
        loading: false,
    }),
    mounted() {
        this.init();
    },
    watch: {
        _image() {
            if (this._image) this.setInitialIndex();
        },
        _review() {
            if (this._review) this.init();
        },
        index() {
            this.$emit("setImage", this.images[this.index]?._id);
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            if (!this._review) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                var { images } = await api.v1.shop.reviewImages.gets({
                    headers: { sort: JSON.stringify({ createdAt: 1 }) },
                    params: { _review: this._review },
                });

                this.images = images;
                this.setInitialIndex();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        setInitialIndex() {
            this.index = this.images.findIndex(({ _id }) => _id == this._image) || 0;
        },
    },
};
</script>

<style></style>
