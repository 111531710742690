var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium w-md-80px"
  }, [_vm._v("배송")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)")])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }