var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('product-groups', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var groups = _ref.groups;
        return [_c('v-row', {
          staticClass: "row--xxs"
        }, [_vm._l(groups, function (group) {
          return [_c('v-col', {
            key: group.value,
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-chip', {
            attrs: {
              "small": "",
              "color": "grey lighten-5",
              "text-color": group.color,
              "to": {
                path: '/shop/products',
                query: {
                  groups: group.value
                }
              }
            }
          }, [_vm._v(_vm._s(group.text))])], 1)];
        })], 2)];
      }
    }])
  }, 'product-groups', {
    product: _vm.product
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }