<template>
    <u-dialog v-model="shows">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template #titleContents>
            <span>이미지 리뷰</span>
            <v-fade-transition>
                <v-btn v-show="tab == 'view'" outlined color="grey" class="v-size--xx-small ml-10px ml-md-16px" @click="tab = 'list'">이미지 리뷰 목록</v-btn>
            </v-fade-transition>
        </template>
        <v-tabs-items v-model="tab">
            <tab-list v-model="viewProps" v-bind="{ _product }" @input="tab = 'view'" />
            <tab-view v-bind="viewProps" @setImage="(image) => (viewProps._image = image)" />
        </v-tabs-items>
    </u-dialog>
</template>

<script>
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TabList from "./tab-list.vue";
import TabView from "./tab-view.vue";

export default {
    components: {
        UDialog,
        TabList,
        TabView,
    },
    props: {
        isList: { type: Boolean, default: false },
        _product: { type: String },
        _review: { type: String },
        _image: { type: String },
    },
    data: () => ({
        tab: "list",
        shows: false,

        viewProps: {
            _image: null,
            _review: null,
        },
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            const { _image, _review } = this;
            this.viewProps = { _image, _review };
            this.tab = this.isList ? "list" : "view";
        },
    },
};
</script>

<style></style>
