<template>
    <!-- 총 상품 금액 -->
    <v-sheet class="pt-lg-30px">
        <v-row no-gutters justify="space-between" align="center">
            <v-col cols="auto">
                <!-- <div class="txt txt--xs">총 상품 금액</div> -->
                <div class="txt txt--sm">
                    TOTAL <span class="grey--text text--lighten-3">(QUANTITY)</span>
                </div>
            </v-col>
            <!-- <v-spacer /> -->
            <v-col cols="auto">
                <span class="d-flex align-center line-height-1 grey--text text--darken-4">
                    <strong class="font-size-20 font-size-lg-24">{{ totalPrice.format() }}원</strong>
                    <!-- <span class="pl-2px">원</span> -->
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    props: {
        carts: { type: Array, default: () => [] },
    },
    computed: {
        totalPrice() {
            return this.carts.reduce((totalPrice, cart) => {
                return totalPrice + cart.salePrice * cart.amount;
            }, 0);
        },
    },
};
</script>

<style>
</style>