<template>
    <v-row align-md="center" class="ma-n8px">
        <v-col cols="auto" class="pa-8px">
            <v-card style="overflow: hidden">
                <router-link v-bind="{ to }">
                    <v-img min-width="78" :aspect-ratio="1 / 1" :src="product.thumb?.url">
                        <template #placeholder>
                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                        </template>
                    </v-img>
                </router-link>
            </v-card>
        </v-col>
        <v-col class="pa-8px">
            <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="product?.flags?.length">
                {{ product.flags.map((text) => `[${text}]`).join(" ") }}
            </div>
            <router-link v-bind="{ to }" class="d-block mb-12px">
                <span class="txt txt--sm txt--dark font-weight-medium">{{ product.name }}</span>
            </router-link>
            <div v-for="(cart, index) in carts" :key="index" :class="{ 'mt-4px': index != 0 }">
                <v-row align="center" class="row--xxs">
                    <v-col v-if="cart._option || cart._supply || cart._related" cols="12" sm="auto">
                        <v-chip v-if="cart._option" x-small outlined color="grey">옵션</v-chip>
                        <v-chip v-if="cart._supply" x-small outlined color="grey">추가</v-chip>
                        <v-chip v-if="cart._related" x-small outlined color="grey">추가</v-chip>
                    </v-col>
                    <v-col cols="12" sm="" class="txt txt--xs">
                        <span>
                            {{ $decode__productOptionName(cart.name) }} / {{ cart.amount.format() }}개 <span v-if="cart._supply"> (+{{ cart.price.format() }}원) </span>
                        </span>
                        <div v-if="cart.requestMessage">
                            {{ cart.requestMessage }}
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { initProduct } from "@/assets/variables";
export default {
    props: {
        product: { type: Object, default: initProduct },
        carts: { type: Array, default: () => [] },
    },
    computed: {
        to() {
            let _id = this.product?._id;
            if (this.product?.variation?.enabled) {
                _id = this.product?.variation?.head?._id || _id;
            }

            return `/shop/products/${_id}`;
        },
    },
};
</script>

<style></style>
