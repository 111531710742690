<template>
    <v-sheet v-bind="$attrs"> <slot v-bind="{ items, active, setSort }" /> </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        value: { type: Number, default: 24 },
    },
    computed: {
        ...mapGetters(["displayCodes"]),
        items() {
            const { groups, byPrice, ...query } = this.$route.query;

            delete query.page;

            const items = [];
            items.push({
                text: "전체",
                to: { query: { ...query } },
                isActive: ![groups, byPrice].some((item) => item != undefined),
            });

            items.push(
                ...[...this.displayCodes.filter(({ shows }) => shows)].map((item) => ({
                    text: item.text,
                    to: { query: { ...query, groups: item.value } },
                    isActive: groups == item.value,
                }))
            );

            items.push(
                {
                    text: "높은 가격순",
                    to: { query: { ...query, byPrice: -1 } },
                    isActive: byPrice == -1,
                },
                {
                    text: "낮은 가격순",
                    to: { query: { ...query, byPrice: 1 } },
                    isActive: byPrice == 1,
                }
            );

            return items;
        },
        active() {
            return this.items.find(({ isActive }) => isActive);
        },
    },
    methods: {
        setSort(item) {
            this.$router.push(this.items.find(({ text }) => text == item.text)?.to);
        },
    },
};
</script>
