<template>
    <v-btn v-bind="attrs">{{ name }}</v-btn>
</template>

<script>
import { initProduct } from "@/assets/variables";
export default {
    props: {
        product: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    computed: {
        attrs() {
            const { ...attrs } = this.$attrs;

            delete attrs.to;
            delete attrs.href;

            const { isOuter, url } = this.product.link;
            if (isOuter) {
                attrs.href = url;
                attrs.target = "_blank";
            } else attrs.to = url;

            attrs.loading = this.loading;

            return attrs;
        },
        name() {
            const { name } = this.product.link;
            return name || "문의하기";
        },
    },
};
</script>

<style></style>
