<template>
    <span v-if="this.point?.usage?.isActive" class="d-inline-flex flex-wrap align-center"> {{ pointText }}시 마일리지 최대 {{ pointAmount }} </span>
</template>

<script>
import api from "@/api";
import { ISSUE_EVENT_TYPES, ISSUE_TYPES, SHOP_POINT_UNITS } from "@/assets/variables";
export default {
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        point: null,
    }),
    computed: {
        pointText() {
            return ISSUE_EVENT_TYPES[this.type]?.text;
        },
        pointAmount() {
            return `${this.point?.value}${SHOP_POINT_UNITS[this.point?.unit]?.suffix}`;
        },
        userInfo() {
            return this.$store.state.user;
        }
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                const { points } = await api.v1.shop.points.getQualifiedPoints({
                    params: {
                        issueType: ISSUE_TYPES.INSTANT.value,
                        issueEventType: ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value,
                    },
                });

                this.point = points?.[0];
                
                this.$emit("search", this.point);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>