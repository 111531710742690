var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', _vm._b({}, 'v-sheet', _vm.$attrs, false), [_vm._t("default", null, null, {
    groups: _vm.groups
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }