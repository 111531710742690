var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', [_c('a', {
    class: {
      'primary--text': _vm.value == 'byThumb'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', 'byThumb');
      }
    }
  }, [_vm._v(" 인기순 ")])]), _c('li', [_c('a', {
    class: {
      'primary--text': _vm.value == 'byLatest'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', 'byLatest');
      }
    }
  }, [_vm._v(" 최신순 ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }