<template>
    <div class="caption warning--text">
        <template v-if="message__uncombinableCoupons">
            <div>{{ message__uncombinableCoupons }}</div>
        </template>
        <template v-if="message__usedOnOtherProducts">
            <div>{{ message__usedOnOtherProducts }}</div>
        </template>
        <template v-if="message__usedOnOtherShipping">
            <div>{{ message__usedOnOtherShipping }}</div>
        </template>
    </div>
</template>

<script>
import { initOrder, initUserCoupon, USAGE_TARGET_PRICES } from "@/assets/variables";
export default {
    props: {
        order: { type: Object, default: initOrder },
        coupon: { type: Object, default: initUserCoupon },
    },
    computed: {
        message__uncombinableCoupons() {
            const isThisCouponCombinable = this.coupon?.usage?.combinable || false;
            if (isThisCouponCombinable) return;

            const couponsUsedPriorToThis = (this.order?.coupons || []).filter(({ _id }) => _id != this.coupon?._id);
            if (0 < couponsUsedPriorToThis.length) {
                let message = "이미 사용중인 다른 쿠폰이 있습니다.";

                const totalCouponPrice = couponsUsedPriorToThis.reduce((sum, { couponPrice = 0 }) => sum + couponPrice, 0);
                if (0 < totalCouponPrice) message += ` (적용금액: ${totalCouponPrice.format()}원)`;

                return message;
            }
        },

        message__usedOnOtherProducts() {
            const isThisNotProductCoupon = this.coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value;
            if (isThisNotProductCoupon) return;

            const couponUsedOnAnotherOne = this.order.coupons.find(({ _id, _product }) => _id == this.coupon._id && _product != this.coupon._product);
            if (couponUsedOnAnotherOne) {
                let message = "다른 항목에 사용중인 쿠폰입니다.";
                if (couponUsedOnAnotherOne.couponPrice) message += ` (적용금액: ${couponUsedOnAnotherOne.couponPrice.format()}원)`;
                return message;
            }
        },

        message__usedOnOtherShipping() {
            const isThisNotShippingCoupon = this.coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value;
            if (isThisNotShippingCoupon) return;

            const couponUsedOnAnotherOne = this.order.coupons.find(({ _id, shippingCode }) => _id == this.coupon._id && shippingCode != this.coupon.shippingCode);

            if (couponUsedOnAnotherOne) {
                let message = "다른 항목에 사용중인 쿠폰입니다.";
                if (couponUsedOnAnotherOne.couponPrice) message += ` (적용금액: ${couponUsedOnAnotherOne.couponPrice.format()}원)`;
                return message;
            }
        },
    },
};
</script>

<style></style>
