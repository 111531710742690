var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "caption warning--text"
  }, [_vm.message__uncombinableCoupons ? [_c('div', [_vm._v(_vm._s(_vm.message__uncombinableCoupons))])] : _vm._e(), _vm.message__usedOnOtherProducts ? [_c('div', [_vm._v(_vm._s(_vm.message__usedOnOtherProducts))])] : _vm._e(), _vm.message__usedOnOtherShipping ? [_c('div', [_vm._v(_vm._s(_vm.message__usedOnOtherShipping))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }