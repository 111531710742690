<template>
    <tit-section v-if="form" :items="computedItems" v-bind="{ itemsPerRow }">
        <template v-for="{ term, type, key, keys, lastKey, props } in computedItems">
            <template v-if="$slots[term]">
                <slot :slot="term" :name="term" />
            </template>
            <template v-else-if="mode == 'form'">
                <template v-if="type == 'address'">
                    <v-address-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :btnAttrs="{ className: 'primary' }" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'bank'">
                    <v-bank-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'currency'">
                    <v-currency-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'date'">
                    <v-text-field v-model="findData(keys)[lastKey]" type="date" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'dates'">
                    <v-row align="center" no-gutters :key="key" :slot="term">
                        <v-col> <v-text-field v-model="findData(keys)[lastKey]['startsAt']" type="date" v-bind="props" @input="emit" /> </v-col>
                        <v-col class="px-10" cols="auto"> ~ </v-col>
                        <v-col> <v-text-field v-model="findData(keys)[lastKey]['endsAt']" type="date" v-bind="props" @input="emit" /> </v-col>
                    </v-row>
                </template>
                <template v-if="type == 'editor'">
                    <naver-smarteditor v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'email'">
                    <v-email-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'file'">
                    <v-file-input v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @change="emit" />
                </template>
                <template v-if="type == 'password'">
                    <v-password-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'phone'">
                    <v-phone-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'rrn'">
                    <v-rrn-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'select'">
                    <v-select v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'switch'">
                    <v-switch v-model="findData(keys)[lastKey]" v-bind="props" inset dense hide-details class="my-10 pl-4" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'text'">
                    <v-text-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
                <template v-if="type == 'textarea'">
                    <v-textarea v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :slot="term" @input="emit" />
                </template>
            </template>
        </template>
    </tit-section>
</template>

<script>
import TitSection from "@/components/dumb/tit-section.vue";
import VRrnField from "@/components/plugins/vuetify/v-rrn-field.vue";
import VBankField from "@/components/plugins/vuetify/v-bank-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import banks from "@/plugins/shop-banks.json";

export default {
    components: {
        TitSection,
        VRrnField,
        VBankField,
        VPhoneField,
        VEmailField,
        VAddressField,
        VPasswordField,
        NaverSmarteditor,
    },
    props: {
        mode: { type: String, default: "form" },

        value: { type: Object, default: () => ({}) },
        items: { type: Array, default: () => [] },
        itemsPerRow: { type: [Number, String], default: 1 },
    },
    data: () => ({
        form: undefined,
    }),
    computed: {
        computedItems() {
            return this.$props.items.map((item) => {
                let { cols, data, key, required, term, type, ...props } = item;

                let keys = key?.split?.(".") || [];
                let lastKey = keys.pop();
                data = data ?? this.findData(keys)?.[lastKey];

                if (type == "address") {
                    let { postcode, address1, address2 } = data || {};
                    data = postcode ? `[${postcode}] ` : "";
                    data += address1 || "";
                    data += " ";
                    data += address2 || "";
                    data = data.trim();
                }

                if (type == "bank") {
                    let { code, accountNumber, accountHolder } = data || {};
                    data = "";
                    if (code) data += `${banks.find((item) => item.code == code)?.name || code || ""} / `;
                    if (accountNumber) data += `${accountNumber} / `;
                    if (accountHolder) data += `${accountHolder}`;
                }

                let computedItem = { cols, data, key, keys, required, lastKey, props, term, type };
                if (this.mode == "form") delete computedItem.data;
                else delete computedItem.required;

                return computedItem;
            });
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
        findData(keys) {
            return keys.reduce((o, key) => o[key], this.form) || this.form;
        },
    },
};
</script>

<style></style>
