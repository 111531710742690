var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePoints,
      expression: "activePoints"
    }]
  }, [_c('div', {
    staticClass: "txt"
  }, [_c('div', {
    staticClass: "font-weight-light mb-8px"
  }, [_vm._v("예정 적립 혜택")]), _c('div', {
    staticClass: "txt--sm"
  }, [_c('order-point-benefit-view', _vm._b({
    on: {
      "search": _vm.count
    }
  }, 'order-point-benefit-view', {
    type: _vm.ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value
  }, false))], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }