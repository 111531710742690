<template>
    <div>
        <page-section v-for="(item, index) in items" :key="index" class="page-section--xs pb-0" :class="{'pt-0' : index == 0}">
            <template v-if="isArray(item)">
                <tit-section-row v-for="child in item" :key="child.term" v-bind="child">
                    <template v-if="child.data"> {{ child.data }} </template>
                    <slot v-else-if="$slots[child.term]" :name="child.term" />
                    <v-node v-else-if="child.node" :node="child.node" />
                </tit-section-row>
            </template>
            <template v-else>
                <tit-section-row v-bind="item">
                    <template v-if="item.data"> {{ item.data }} </template>
                    <slot v-else-if="$slots[item.term]" :name="item.term" />
                    <v-node v-else-if="item.node" :node="item.node" />
                </tit-section-row>
            </template>
        </page-section>
    </div>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import TitSectionRow from "./tit-section-row.vue";
export default {
    components: {
        vNode: { functional: true, render: (h, ctx) => ctx.props.node },
        PageSection,
        TitSectionRow,
    },
    props: {
        items: { type: Array, default: () => [] },
        itemsPerRow: { type: [Number, String], default: 1 },
    },
    computed: {
        defaultCols() {
            return 12 / this.itemsPerRow;
        },
    },
    methods: {
        isArray(item) {
            return Array.isArray(item);
        },
    },
};
</script>

<style></style>
