<template>
    <div class="divider-group">
        <ul>
            <li>
                <a :class="{ 'primary--text': value == 'byThumb' }" @click="$emit('input', 'byThumb')"> 인기순 </a>
            </li>
            <li>
                <a :class="{ 'primary--text': value == 'byLatest' }" @click="$emit('input', 'byLatest')"> 최신순 </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, default: "byThumb" },
    },
};
</script>

<style></style>
