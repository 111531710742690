<template>
    <div class="mb-20px mb-md-32px" v-if="images.length">
        <v-divider class="mb-20px mb-md-32px" />

        <v-row class="row-cols-8 row--xs">
            <v-col v-for="(image, index) in images" :key="image._id">
                <review-images-dialog v-bind="{ _product, _review: image._review, _image: image._id }" :isList="index == 7">
                    <template #activator="{ attrs, on }">
                        <v-card rounded="md" class="overflow-hidden" v-bind="attrs" v-on="on">
                            <v-img cover :aspect-ratio="1 / 1" :src="image?.url">
                                <v-overlay v-if="index == 7" absolute z-index="0">
                                    <span class="font-size-14 font-size-md-16 text-decoration-underline">더 보기</span>
                                </v-overlay>
                            </v-img>
                        </v-card>
                    </template>
                </review-images-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";

import ReviewImagesDialog from "./dialog/review-images-dialog.vue";

export default {
    components: {
        ReviewImagesDialog,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        images: [],
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return this.images;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { images, summary } = await api.v1.shop.reviewImages.gets({
                    headers: { skip: 0, limit: 8, sort: JSON.stringify({ createdAt: -1 }) },
                    params: this.$props,
                });
                this.images = images;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style></style>
