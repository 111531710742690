var render = function render(){
  var _vm$point_total, _vm$point_total$forma;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return 0 < _vm.point_total ? _c('v-card', {
    staticClass: "pa-24px",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "txt"
  }, [_c('v-row', {
    staticClass: "row--xxs txt--dark font-weight-bold"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("최대 적립 마일리지")]), _c('v-col', {
    staticClass: "text-end",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s((_vm$point_total = _vm.point_total) === null || _vm$point_total === void 0 ? void 0 : (_vm$point_total$forma = _vm$point_total.format) === null || _vm$point_total$forma === void 0 ? void 0 : _vm$point_total$forma.call(_vm$point_total)) + "M")])], 1), _vm._l(_vm.items, function (item) {
    var _item$value, _item$value$format;
    return [_c('v-row', {
      key: item.title,
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_vm._v("- " + _vm._s(item.title))]), _c('v-col', {
      staticClass: "text-end txt--dark",
      attrs: {
        "cols": "6"
      }
    }, [_vm._v("+" + _vm._s((_item$value = item.value) === null || _item$value === void 0 ? void 0 : (_item$value$format = _item$value.format) === null || _item$value$format === void 0 ? void 0 : _item$value$format.call(_item$value)) + "M")])], 1)];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }