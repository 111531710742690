<template>
    <product-list-sort color="transparent">
        <template slot-scope="{ items, active, setSort }">
            <v-select :value="active" v-bind="{ ...attrs_input, items }" item-text="text" return-object placeholder="정렬" class="mw-md-160px ml-auto" @change="setSort" />
        </template>
    </product-list-sort>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ProductListSort from "./product-list-sort.vue";

export default {
    components: {
        ProductListSort,
    },

    data: () => ({
        attrs_input,
    }),
};
</script>

<style lang="scss" scoped>
.on {
    color: var(--v-primary-base);
}
</style>
