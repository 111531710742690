var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")]), _c('div', {
    staticClass: "d-flex align-center font-size-20 font-size-lg-16"
  }, [_vm.product.discountPrice ? [_c('strong', {
    staticClass: "secondary--text mr-8px"
  }, [_vm._v(_vm._s(_vm.product.discountRate) + "%")])] : _vm._e(), _vm.product.salePrice ? [_c('strong', {
    staticClass: "d-inline-flex align-center"
  }, [_vm._v(" " + _vm._s(_vm.product.salePrice.format()) + " "), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])] : _vm._e(), _vm.product.discountPrice ? [_c('span', {
    staticClass: "font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px"
  }, [_vm._v(" " + _vm._s(`${_vm.product.price.format()}원`) + " ")])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }