var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "icon": "",
      "rounded": "",
      "outlined": "",
      "color": "transparent"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined grey--text text--darken-4",
    on: {
      "click": _vm.share
    }
  }, [_vm._v("share")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }