var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-sm-300px mx-auto"
  }, [_c('v-card', _vm._b({
    staticClass: "overflow-hidden",
    attrs: {
      "rounded": "",
      "outlined": "",
      "height": "300"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-carousel', {
    attrs: {
      "hide-delimiters": "",
      "show-arrows": false
    },
    model: {
      value: _vm.index,
      callback: function ($$v) {
        _vm.index = $$v;
      },
      expression: "index"
    }
  }, _vm._l(_vm.images, function (image) {
    return _c('v-carousel-item', {
      key: image._id
    }, [_c('v-img', _vm._b({
      attrs: {
        "contain": "",
        "aspect-ratio": 1 / 1
      }
    }, 'v-img', image, false))], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-slide-group', {
    attrs: {
      "show-arrows": "",
      "center-active": ""
    },
    model: {
      value: _vm.index,
      callback: function ($$v) {
        _vm.index = $$v;
      },
      expression: "index"
    }
  }, _vm._l(_vm.images, function (image) {
    return _c('v-slide-item', {
      key: image._id,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var active = _ref.active,
            toggle = _ref.toggle;
          return [_c('v-card', {
            staticClass: "product-intro__thumb-button overflow-hidden ma-1",
            attrs: {
              "outlined": "",
              "color": active ? 'primary' : 'transparent'
            },
            on: {
              "click": toggle
            }
          }, [_c('v-img', _vm._b({
            attrs: {
              "width": "60",
              "height": "60"
            }
          }, 'v-img', image, false))], 1)];
        }
      }], null, true)
    });
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }