var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "titleContents",
      fn: function () {
        return [_c('span', [_vm._v("이미지 리뷰")]), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.tab == 'view',
            expression: "tab == 'view'"
          }],
          staticClass: "v-size--xx-small ml-10px ml-md-16px",
          attrs: {
            "outlined": "",
            "color": "grey"
          },
          on: {
            "click": function ($event) {
              _vm.tab = 'list';
            }
          }
        }, [_vm._v("이미지 리뷰 목록")])], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('tab-list', _vm._b({
    on: {
      "input": function ($event) {
        _vm.tab = 'view';
      }
    },
    model: {
      value: _vm.viewProps,
      callback: function ($$v) {
        _vm.viewProps = $$v;
      },
      expression: "viewProps"
    }
  }, 'tab-list', {
    _product: _vm._product
  }, false)), _c('tab-view', _vm._b({
    on: {
      "setImage": function (image) {
        return _vm.viewProps._image = image;
      }
    }
  }, 'tab-view', _vm.viewProps, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }