var render = function render(){
  var _vm$review, _vm$review$user, _vm$review2, _vm$review3, _vm$review4, _vm$review5, _vm$review6, _vm$review6$createdAt, _vm$review6$createdAt2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": "view"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('tab-view-images', _vm._b({
    on: {
      "setImage": function (image) {
        return _vm.$emit('setImage', image);
      }
    }
  }, 'tab-view-images', {
    _image: _vm._image,
    _review: _vm._review
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('div', {
    staticClass: "mt-20px mt-sm-0 ml-sm-20px ml-lg-12px"
  }, [_c('div', {
    staticClass: "pb-20px pb-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-card', {
    staticClass: "w-40px",
    attrs: {
      "rounded": "circle",
      "color": "#d9d9d9"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center h-100"
  }, [_c('span', {
    staticClass: "material-icons font-size-20 font-size-md-24 white--text"
  }, [_vm._v("person")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_c('span', [_vm._v(_vm._s(((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$user = _vm$review.user) === null || _vm$review$user === void 0 ? void 0 : _vm$review$user.username) || "탈퇴회원"))])]), _c('div', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v(_vm._s(((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : _vm$review2.productName) || "-") + " / " + _vm._s(_vm.$decode__productOptionName(((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : _vm$review3.purchaseName) || "-")))])])], 1)], 1), _c('div', {
    staticClass: "pb-8px"
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": ((_vm$review4 = _vm.review) === null || _vm$review4 === void 0 ? void 0 : _vm$review4.star) || 0
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(((_vm$review5 = _vm.review) === null || _vm$review5 === void 0 ? void 0 : _vm$review5.content) || "-") + " ")]), _c('div', {
    staticClass: "d-flex align-center pt-20px pt-md-24px"
  }, [_c('span', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v(_vm._s(((_vm$review6 = _vm.review) === null || _vm$review6 === void 0 ? void 0 : (_vm$review6$createdAt = _vm$review6.createdAt) === null || _vm$review6$createdAt === void 0 ? void 0 : (_vm$review6$createdAt2 = _vm$review6$createdAt.toDateTime) === null || _vm$review6$createdAt2 === void 0 ? void 0 : _vm$review6$createdAt2.call(_vm$review6$createdAt)) || "-"))])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }