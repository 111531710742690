<template>
    <div v-show="activePoints">
        <div class="txt">
            <div class="font-weight-light mb-8px">예정 적립 혜택</div>
            <div class="txt--sm">
                <order-point-benefit-view v-bind="{ type: ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value }" @search="count" />
            </div>
        </div>
    </div>
</template>

<script>
import { ISSUE_EVENT_TYPES } from "@/assets/variables";

import OrderPointBenefitView from "@/components/client/shop/order/order-benefits/order-point-benefit-view.vue";

export default {
    components: {
        OrderPointBenefitView,
    },
    data: () => ({
        ISSUE_EVENT_TYPES,
        activePoints: 0,
    }),
    methods: {
        count(point) {
            if (point?.usage?.isActive) this.activePoints++;
        },
    },
};
</script>

<style>
</style>