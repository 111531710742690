var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.images.length ? _c('div', {
    staticClass: "mb-20px mb-md-32px"
  }, [_c('v-divider', {
    staticClass: "mb-20px mb-md-32px"
  }), _c('v-row', {
    staticClass: "row-cols-8 row--xs"
  }, _vm._l(_vm.images, function (image, index) {
    return _c('v-col', {
      key: image._id
    }, [_c('review-images-dialog', _vm._b({
      attrs: {
        "isList": index == 7
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "overflow-hidden",
            attrs: {
              "rounded": "md"
            }
          }, 'v-card', attrs, false), on), [_c('v-img', {
            attrs: {
              "cover": "",
              "aspect-ratio": 1 / 1,
              "src": image === null || image === void 0 ? void 0 : image.url
            }
          }, [index == 7 ? _c('v-overlay', {
            attrs: {
              "absolute": "",
              "z-index": "0"
            }
          }, [_c('span', {
            staticClass: "font-size-14 font-size-md-16 text-decoration-underline"
          }, [_vm._v("더 보기")])]) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    }, 'review-images-dialog', {
      _product: _vm._product,
      _review: image._review,
      _image: image._id
    }, false))], 1);
  }), 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }