var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.coupons.length ? _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('u-dialog', {
    attrs: {
      "title": "적용할 수 있는 쿠폰",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-row', {
          staticClass: "row--xxs font-size-14 primary--text",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "d-inline-flex",
          attrs: {
            "cols": "auto"
          }
        }, [_c('icon-ticket')], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v("적용할 수 있는 쿠폰이 있습니다.")])]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-btn--width-fixed"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v("쿠폰 확인")])], 1)], 1)];
      }
    }], null, false, 3562763172)
  }, [_c('v-row', [_vm._l(_vm.coupons, function (coupon) {
    return [_c('v-col', {
      key: coupon._id,
      attrs: {
        "cols": "12"
      }
    }, [_c('shop-coupon-item', _vm._b({}, 'shop-coupon-item', {
      coupon
    }, false))], 1)];
  })], 2)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }