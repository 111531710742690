var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-quaternary', {
          attrs: {
            "tit": "베스트상품",
            "bg": "/images/shop/best/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('visual-breadcrumbs')];
            },
            proxy: true
          }, {
            key: "tab",
            fn: function () {
              return [_c('best-list-tabs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('shop-product-items', _vm._l(_vm.bestProducts, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product,
        "cols": "12",
        "rank": index + 1,
        "crown": ""
      }
    });
  }), 1), _c('v-divider', {
    staticClass: "my-40px my-lg-60px",
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.items, function (item, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": item,
        "sm": "6",
        "md": "3",
        "lg": "3",
        "rank": item.rank
      }
    });
  }), 1), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }