<template>
    <div>
        <!-- <div>
            <v-row no-gutters>
                <v-col cols="12" md="auto">
                    <div class="txt--dark font-weight-medium w-md-80px">배송비</div>
                </v-col>
                <v-col cols="12" md="">
                    <div>{{ product.deliveryMessage }}</div>
                </v-col>
            </v-row>
        </div>
        <div class="pt-4px pt-md-8px">-->
        <div>
            <v-row no-gutters>
                <v-col cols="12" md="auto">
                    <div class="txt--dark font-weight-medium w-md-80px">배송</div>
                </v-col>
                <v-col cols="12" md="">
                    <div>상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. <br class="d-none d-md-block" />택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    components: {},
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>