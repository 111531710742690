<template>
    <info-section class="txt txt--xs" v-if="coupons.length">
        <u-dialog title="적용할 수 있는 쿠폰" width="600">
            <template #activator="{ attrs, on }">
                <v-row align="center" class="row--xxs font-size-14 primary--text">
                    <v-col cols="auto" class="d-inline-flex">
                        <icon-ticket />
                    </v-col>
                    <v-col cols="auto">
                        <span class="font-weight-medium">적용할 수 있는 쿠폰이 있습니다.</span>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on" class="v-btn--width-fixed">쿠폰 확인</v-btn>
                    </v-col>
                </v-row>
            </template>
            <v-row>
                <template v-for="coupon in coupons">
                    <v-col cols="12" :key="coupon._id">
                        <shop-coupon-item v-bind="{ coupon }" />
                    </v-col>
                </template>
            </v-row>
        </u-dialog>
    </info-section>
</template>
<script>
import api from "@/api";
import { initProduct } from "@/assets/variables";
import { btn_secondary } from "@/assets/variables";

import InfoSection from "./info/info-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ShopCouponItem from "@/components/client/shop/coupons/shop-coupon-item.vue";
import IconTicket from "@/components/client/icons/icon-ticket.vue";

export default {
    components: {
        InfoSection,
        UDialog,
        ShopCouponItem,
        IconTicket,
        InfoSection,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        btn_secondary,

        coupons: [],
    }),
    mounted() {
        this.init();
    },
    watch: {
        product() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.product._id) return;

            const { coupons } = await api.v1.shop.coupons.gets({ params: { _product: this.product?._id } });
            this.coupons = coupons;
        },
    },
};
</script>
