<template>
    <v-card tile outlined class="pa-24px" v-if="0 < point_total">
        <div class="txt">
            <v-row class="row--xxs txt--dark font-weight-bold">
                <v-col cols="6">최대 적립 마일리지</v-col>
                <v-col cols="6" class="text-end">{{ point_total?.format?.() }}M</v-col>
            </v-row>
            <template v-for="item in items">
                <v-row class="row--xxs" :key="item.title">
                    <v-col cols="6">- {{ item.title }}</v-col>
                    <v-col cols="6" class="text-end txt--dark">+{{ item.value?.format?.() }}M</v-col>
                </v-row>
            </template>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";

import { initProduct, ISSUE_EVENT_TYPES, ISSUE_TYPES, SHOP_POINT_UNITS } from "@/assets/variables";
import { mapState } from "vuex";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        shopPoints: undefined,

        loading: false,
    }),
    computed: {
        ...mapState(["_user"]),

        items() {
            return [
                {
                    title: "구매 확정 적립",
                    value: this.calcShopPointAmount(ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value),
                },
                {
                    title: "리뷰 적립 (기본)",
                    value: this.calcShopPointAmount(ISSUE_EVENT_TYPES.REVIEW_TEXT_WRITE.value),
                },
                {
                    title: "리뷰 적립 (이미지)",
                    value: this.calcShopPointAmount(ISSUE_EVENT_TYPES.REVIEW_IMG_UPLOAD.value),
                },
            ].filter((item) => 0 < item.value);
        },

        point_total() {
            return this.items.reduce((sum, { value }) => sum + value, 0);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.shopPoints = undefined;

            try {
                let { points } = await api.v1.shop.points.getQualifiedPoints({
                    params: {
                        issueType: ISSUE_TYPES.INSTANT.value,
                    },
                });
                this.shopPoints = points;
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        calcShopPointAmount(issueEventType) {
            const shopPoint = (this.shopPoints || []).find((item) => item?.issue?.event?.type == issueEventType);
            if (!shopPoint) return 0;

            switch (shopPoint.unit) {
                case SHOP_POINT_UNITS.AMOUNT.value: {
                    return shopPoint.value;
                }
                case SHOP_POINT_UNITS.PERCENT.value: {
                    return Math.floor((this.product?.salePrice || 0) * (shopPoint.value / 100));
                }
                default: {
                    return 0;
                }
            }
        },
    },
    watch: {
        _user() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>