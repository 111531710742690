<template>
    <v-btn icon rounded outlined color="transparent"><span class="material-icons-outlined grey--text text--darken-4" @click="share">share</span></v-btn>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
    methods: {
        share() {
            navigator.share({
                text: this.product.name,
                url: window.location.href,
            });
        },
    },
};
</script>

<style>
</style>