var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "method": "post",
      "action": "https://payu.nicepay.co.kr/main.do",
      "accept-charset": "euc-kr"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "MID"
    },
    domProps: {
      "value": _vm.merchantID
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "MallUserID"
    },
    domProps: {
      "value": _vm.buyerUsername
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "UserKey"
    },
    domProps: {
      "value": _vm.buyerNiceCode
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "AuthType",
      "value": "2"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerName"
    },
    domProps: {
      "value": _vm.buyerName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerTel"
    },
    domProps: {
      "value": _vm.buyerTel
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerEmail"
    },
    domProps: {
      "value": _vm.buyerEmail
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "LogoImgUrl"
    },
    domProps: {
      "value": _vm.logoUrl
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Moid"
    },
    domProps: {
      "value": _vm.moid
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "EdiDate"
    },
    domProps: {
      "value": _vm.ediDate
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Amt"
    },
    domProps: {
      "value": _vm.amt
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "GoodsName"
    },
    domProps: {
      "value": _vm.goodsName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ReturnUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "RcvName"
    },
    domProps: {
      "value": _vm.receiverName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "RcvTel"
    },
    domProps: {
      "value": _vm.receiverTel
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "RcvPostNo"
    },
    domProps: {
      "value": _vm.receiverPostcode
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "RcvAddr1"
    },
    domProps: {
      "value": _vm.receiverAddress1
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "RcvAddr2"
    },
    domProps: {
      "value": _vm.receiverAddress2
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "SignData"
    },
    domProps: {
      "value": _vm.hashString
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "SkinType",
      "value": "green"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "CharSet",
      "value": "utf-8"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }