var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.length, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-file-input', {
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "",
        "placeholder": "파일을 업로드해 주세요.",
        "accept": "image/*"
      },
      on: {
        "change": _vm.emit
      },
      model: {
        value: _vm.images[i - 1],
        callback: function ($$v) {
          _vm.$set(_vm.images, i - 1, $$v);
        },
        expression: "images[i - 1]"
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }