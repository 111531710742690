<template>
    <div class="d-flex align-center">
        <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="+(product?.meta?.reviews?.star__average || 0).toFixed(1)" />
        <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ product?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>