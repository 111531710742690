<template>
    <v-sheet v-bind="$attrs">
        <slot v-bind="{ groups }" />
    </v-sheet>
</template>

<script>
import { initProduct, initSettingShopDisplayCode } from "@/assets/variables";
import { mapGetters } from "vuex";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
    computed: {
        ...mapGetters(["displayCodes"]),
        groups() {
            return this.product.groups.map((value) => initSettingShopDisplayCode({ ...(this.displayCodes.find((item) => item.value == value) || { value, shows: false }) })).filter(({ shows }) => shows);
        },
    },
};
</script>

<style>
</style>