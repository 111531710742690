var render = function render(){
  var _vm$product, _vm$product$meta, _vm$product$meta$revi, _vm$product2, _vm$product2$meta, _vm$product2$meta$rev, _vm$product2$meta$rev2, _vm$product2$meta$rev3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": +(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$meta = _vm$product.meta) === null || _vm$product$meta === void 0 ? void 0 : (_vm$product$meta$revi = _vm$product$meta.reviews) === null || _vm$product$meta$revi === void 0 ? void 0 : _vm$product$meta$revi.star__average) || 0).toFixed(1)
    }
  }), _c('span', {
    staticClass: "font-size-14 grey--text text--lighten-1 pl-4px"
  }, [_vm._v("(" + _vm._s(((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$meta = _vm$product2.meta) === null || _vm$product2$meta === void 0 ? void 0 : (_vm$product2$meta$rev = _vm$product2$meta.reviews) === null || _vm$product2$meta$rev === void 0 ? void 0 : (_vm$product2$meta$rev2 = _vm$product2$meta$rev.count__total) === null || _vm$product2$meta$rev2 === void 0 ? void 0 : (_vm$product2$meta$rev3 = _vm$product2$meta$rev2.format) === null || _vm$product2$meta$rev3 === void 0 ? void 0 : _vm$product2$meta$rev3.call(_vm$product2$meta$rev2)) || 0) + ")")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }