var render = function render(){
  var _vm$product$thumb, _vm$product, _vm$product$flags;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', {
    attrs: {
      "min-width": "78",
      "aspect-ratio": 1 / 1,
      "src": (_vm$product$thumb = _vm.product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [(_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$flags = _vm$product.flags) !== null && _vm$product$flags !== void 0 && _vm$product$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(" " + _vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")) + " ")]) : _vm._e(), _c('router-link', _vm._b({
    staticClass: "d-block mb-12px"
  }, 'router-link', {
    to: _vm.to
  }, false), [_c('span', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(_vm.product.name))])]), _vm._l(_vm.carts, function (cart, index) {
    return _c('div', {
      key: index,
      class: {
        'mt-4px': index != 0
      }
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [cart._option || cart._supply || cart._related ? _c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [cart._option ? _c('v-chip', {
      attrs: {
        "x-small": "",
        "outlined": "",
        "color": "grey"
      }
    }, [_vm._v("옵션")]) : _vm._e(), cart._supply ? _c('v-chip', {
      attrs: {
        "x-small": "",
        "outlined": "",
        "color": "grey"
      }
    }, [_vm._v("추가")]) : _vm._e(), cart._related ? _c('v-chip', {
      attrs: {
        "x-small": "",
        "outlined": "",
        "color": "grey"
      }
    }, [_vm._v("추가")]) : _vm._e()], 1) : _vm._e(), _c('v-col', {
      staticClass: "txt txt--xs",
      attrs: {
        "cols": "12",
        "sm": ""
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$decode__productOptionName(cart.name)) + " / " + _vm._s(cart.amount.format()) + "개 "), cart._supply ? _c('span', [_vm._v(" (+" + _vm._s(cart.price.format()) + "원) ")]) : _vm._e()]), cart.requestMessage ? _c('div', [_vm._v(" " + _vm._s(cart.requestMessage) + " ")]) : _vm._e()])], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }