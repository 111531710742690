var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": "list"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_vm._l(_vm.images, function (image) {
    var _vm$value;
    return [_c('v-col', {
      key: image._id,
      attrs: {
        "cols": "3",
        "sm": "2"
      }
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "outlined": "",
        "rounded": "md",
        "color": ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value._image) == (image === null || image === void 0 ? void 0 : image._id) ? 'primary' : 'transparent'
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('input', {
            _image: image._id,
            _review: image._review
          });
        }
      }
    }, [_c('v-img', _vm._b({
      attrs: {
        "cover": "",
        "aspect-ratio": 1 / 1
      }
    }, 'v-img', image, false))], 1)], 1)];
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }