<template>
    <v-tab-item value="view">
        <v-row no-gutters>
            <!-- S: 상품 이미지 -->
            <v-col cols="12" sm="5">
                <tab-view-images v-bind="{ _image, _review }" @setImage="(image) => $emit('setImage', image)" />
            </v-col>
            <!-- E: 상품 이미지 -->
            <v-col cols="12" sm="">
                <!-- S: 리뷰내용 -->
                <div class="mt-20px mt-sm-0 ml-sm-20px ml-lg-12px">
                    <!-- head -->
                    <div class="pb-20px pb-md-24px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="auto">
                                <v-card rounded="circle" color="#d9d9d9" class="w-40px">
                                    <v-img :aspect-ratio="1 / 1">
                                        <div class="d-flex align-center justify-center h-100">
                                            <span class="material-icons font-size-20 font-size-md-24 white--text">person</span>
                                        </div>
                                    </v-img>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="">
                                <div class="txt txt--xs txt--dark font-weight-medium">
                                    <span>{{ review?.user?.username || "탈퇴회원" }}</span>
                                </div>
                                <div class="txt txt--xs txt--light">{{ review?.productName || "-" }} / {{ $decode__productOptionName(review?.purchaseName || "-") }}</div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- body -->
                    <div class="pb-8px">
                        <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="review?.star || 0" />
                    </div>
                    <div class="txt txt--xs">
                        {{ review?.content || "-" }}
                    </div>

                    <div class="d-flex align-center pt-20px pt-md-24px">
                        <span class="txt txt--xs txt--light">{{ review?.createdAt?.toDateTime?.() || "-" }}</span>
                        <!-- <span class="txt txt--xs txt--light px-4px">·</span>
                        <v-btn small text color="grey lighten-1" class="v-btn--text-fit">신고</v-btn> -->
                    </div>
                </div>
                <!-- E: 리뷰내용 -->
            </v-col>
        </v-row>
    </v-tab-item>
</template>

<script>
import api from "@/api";

import TabViewImages from "./tab-view-images.vue";

export default {
    components: {
        TabViewImages,
    },
    props: {
        _image: { type: String },
        _review: { type: String },
    },
    data: () => ({
        review: undefined,
    }),
    mounted() {
        this.init();
    },
    watch: {
        _review() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { review } = await api.v1.shop.reviews.get({ _id: this._review });
                this.review = review;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
