var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({}, 'v-btn', _vm.attrs, false), [_vm._v(_vm._s(_vm.name))]);

}
var staticRenderFns = []

export { render, staticRenderFns }