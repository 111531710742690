<template>
    <vertical-table v-bind="{ items }" />
</template>

<script>
import { mapGetters } from "vuex";

import VerticalTable from "@/components/dumb/vertical-table.vue";

export default {
    components: {
        VerticalTable,
    },
    props: {
        carts: { type: Array, default: () => [] },
        selected: { type: Array, default: () => [] },
    },
    computed: {
        ...mapGetters(["userLevel"]),
        items() {
            const productPrice = this.$getProductPrice(this.carts);
            const discountPrice = this.$getDiscountPrice(this.carts);
            const levelDiscountPrice = this.$getLevelDiscountPrice(this.carts, this.userLevel);
            const couponPrice = this.selected?.[0]?.couponPrice || 0;
            const totalPrice = productPrice - discountPrice - couponPrice;

            return [
                { term: "상품금액", data: productPrice },
                { term: "상품할인", data: discountPrice },
                ...(levelDiscountPrice
                    ? [
                          {
                              term: "등급할인",
                              data: levelDiscountPrice,
                          },
                      ]
                    : []),
                { term: "쿠폰할인", data: couponPrice },
                { term: "할인적용금액", data: totalPrice },
            ].map((item) => ({ ...item, data: `${item.data?.format?.() || item.data}원` }));
        },
    },
};
</script>

<style></style>
