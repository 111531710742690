var render = function render(){
  var _vm$product, _vm$product$flags;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$flags = _vm$product.flags) !== null && _vm$product$flags !== void 0 && _vm$product$flags.length ? _c('v-sheet', [_vm._v(" " + _vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")) + " ")]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }