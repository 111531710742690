<template>
    <div>
        <template v-if="detect == 'pc'">
            <v-breadcrumbs :items="breadcrumbsItems" class="d-none d-lg-flex">
                <template #divider>
                    <v-icon small>mdi-chevron-right</v-icon>
                </template>
                <template #item="{ item }">
                    <template v-if="item.text == 'HOME'">
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else-if="item.text == '쇼핑'">
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else>
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </template>
            </v-breadcrumbs>
        </template>

        <template v-if="detect == 'mobile'">
            <v-breadcrumbs :items="breadcrumbsItems" class="d-lg-none">
                <template #divider>
                    <v-icon small>mdi-chevron-right</v-icon>
                </template>
                <template #item="{ item }">
                    <template v-if="item.text == '홈'">
                        <v-breadcrumbs-item to="/" exact>
                            <v-icon class="font-size-20">mdi-home-outline</v-icon>
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else-if="item.text == '쇼핑'">
                        <v-breadcrumbs-item to="/shop/products" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else>
                        <v-breadcrumbs-item @click="categoryMenu !== item.categories ? (categoryMenu = item.categories) : (categoryMenu = undefined)">
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </template>
            </v-breadcrumbs>
            <template v-for="item in breadcrumbsItems">
                <v-expand-transition v-if="item.categories" :key="item.text">
                    <v-list v-show="categoryMenu === item.categories" class="breadcrumbs-list">
                        <template v-if="item.to.query.category != undefined && item.to.query.category == category.code">
                            <v-list-item exact :to="{ ...item.parentTo, query: { ...$route.query, ...(item?.parentTo?.query || {}) } }" @click.prevent="((categoryMenu = undefined) || true) && $router.push({ query: { category: item.parentTo.query.category } })"> 전체보기 </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item exact :to="{ ...item.to, query: { ...$route.query, ...(item?.to?.query || {}) } }" @click.prevent="((categoryMenu = undefined) || true) && $router.push({ query: { category: item.to.query.category } })"> 전체보기 </v-list-item>
                        </template>
                        <template v-for="item in item.categories">
                            <v-list-item exact :to="{ query: { ...$route.query, category: item.code } }" :key="item._id" @click.prevent="((categoryMenu = undefined) || true) && $router.push({ query: { category: item.to.query.category } })">
                                {{ item.name }}
                            </v-list-item>
                        </template>
                    </v-list>
                </v-expand-transition>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
import detect from "@/plugins/detect";

export default {
    data: () => ({
        detect,
        categoryMenu: undefined,
    }),
    computed: {
        ...mapState(["categories", "areCategoriesLoaded"]),
        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode);
        },
        breadcrumbsItems() {
            const items = [
                { text: "홈", to: "/" },
                { text: "쇼핑", to: "/shop/products" },
            ];
            if (this.category) {
                const parents = this.category._parents.reduce((array, _parent) => {
                    const categories = array?.at?.(-1)?.children || this.categories;
                    const category = categories.find((category) => category._id == _parent);
                    if (category) array.push({ category, categories });
                    return array;
                }, []);

                items.push(
                    ...parents.map(({ category: { name, code }, categories }) => ({
                        text: name,
                        to: { query: { category: code } },
                        categories,
                    }))
                );
                items.push({
                    text: this.category?.name,
                    to: { query: { category: this.category.code } },
                    parentTo: items.at(-1)?.to || { query: {} },
                    categories: [this.categories, this.categories_children, this.categories_grandChildren, this.categories_granderChildren].find((items) => !!items.find((item) => item._id == this.category._id)).filter(({ _parent }) => _parent == this.category._parent),
                });

                if (detect == "mobile" && 0 < this.category.children?.length) {
                    items.push({
                        text: "전체보기",
                        to: { query: { category: this.category.code } },
                        categories: this.category.children,
                    });
                }
            } else {
                if (detect == "mobile") {
                    items.push({
                        text: "전체보기",
                        to: { query: {} },
                        categories: this.categories,
                    });
                }
            }

            return items.map((item) => ({
                ...item,
                to: {
                    ...(item?.to || {}),
                    query: { ...this.$route.query, ...(item?.to?.query || {}) },
                },
                parentTo: {
                    ...(item?.parentTo || {}),
                    query: { ...this.$route.query, ...(item?.parentTo?.query || {}) },
                },
                disabled: false,
            }));
        },
    },
    methods: {
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
        color: var(--v-grey-darken4) !important;
    }
}
</style>